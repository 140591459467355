
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['configKey']),
  },

  methods: {
    ...mapActions(['updateKey']),
  },
}
