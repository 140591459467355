/* eslint-disable prettier/prettier */

/**
 * Map to transform from hexagram code to hexagram number.
 */
export const HEXAGRAM_CODE_TO_HEXAGRAM_NUMBER: Record<string, number> = {
  '||||||': 1,  '||||¦¦': 34, '|||¦|¦': 5,  '|||¦¦|': 26, '|||¦¦¦': 11, '|||¦||': 9,  '||||¦|': 14, '|||||¦': 43,

  '|¦¦|||': 25, '|¦¦|¦¦': 51, '|¦¦¦|¦': 3,  '|¦¦¦¦|': 27, '|¦¦¦¦¦': 24, '|¦¦¦||': 42, '|¦¦|¦|': 21, '|¦¦||¦': 17,

  '¦|¦|||': 6,  '¦|¦|¦¦': 40, '¦|¦¦|¦': 29, '¦|¦¦¦|': 4,  '¦|¦¦¦¦': 7,  '¦|¦¦||': 59, '¦|¦|¦|': 64, '¦|¦||¦': 47,

  '¦¦||||': 33, '¦¦||¦¦': 62, '¦¦|¦|¦': 39, '¦¦|¦¦|': 52, '¦¦|¦¦¦': 15, '¦¦|¦||': 53, '¦¦||¦|': 56, '¦¦|||¦': 31,

  '¦¦¦|||': 12, '¦¦¦|¦¦': 16, '¦¦¦¦|¦': 8,  '¦¦¦¦¦|': 23, '¦¦¦¦¦¦': 2,  '¦¦¦¦||': 20, '¦¦¦|¦|': 35, '¦¦¦||¦': 45,

  '¦|||||': 44, '¦|||¦¦': 32, '¦||¦|¦': 48, '¦||¦¦|': 18, '¦||¦¦¦': 46, '¦||¦||': 57, '¦|||¦|': 50, '¦||||¦': 28,

  '|¦||||': 13, '|¦||¦¦': 55, '|¦|¦|¦': 63, '|¦|¦¦|': 22, '|¦|¦¦¦': 36, '|¦|¦||': 37, '|¦||¦|': 30, '|¦|||¦': 49,

  '||¦|||': 10, '||¦|¦¦': 54, '||¦¦|¦': 60, '||¦¦¦|': 41, '||¦¦¦¦': 19, '||¦¦||': 61, '||¦|¦|': 38, '||¦||¦': 58,
}
